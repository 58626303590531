<template>
	<div>
		<v-dialog v-model="dialog" width="800" persistent>
			<v-card class="overflow-y-auto">
				<v-card-title class="text-h5 grey lighten-2">{{ $t('shipsituation.planned.plan.plan') }} </v-card-title>
				<div v-if="this.stops.length == 0" class="noselected-title">{{ $t('shipsituation.planned.plan.noSelected') }}</div>
				<v-card-text class="overflow-y-auto">
					<pui-field-set v-for="(stop, index) in stops" :key="stop.id" class="stopsPlanning ma-2 pa-2" :title="stop.vesselname">
						<v-row>
							<v-col cols="11"> </v-col>
							<v-col cols="1"
								><v-btn icon ripple @click="dropStop(index)">
									<v-icon>far fa-times</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<pui-field-set :title="$t('shipsituation.planned.plan.requested')">
							<v-row dense>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.status')" :value="stop.status"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('operations.stops.eta')"
										:value="convertDatesFromStopAIS(stop.eta)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('operations.stops.etd')"
										:value="convertDatesFromStopAIS(stop.etd)"
									></operations-field>
								</v-col>
								<v-col cols="3">
									<operations-field :label="$t('operations.stops.berthname')" :value="stop.berthname"></operations-field>
								</v-col>
								<v-col cols="3">
									<operations-field :label="$t('operations.stops.berthtype')" :value="stop.berthingtypename"></operations-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="$t('shipsituation.planned.plan.plan')">
							<v-row dense>
								<v-col cols="2">
									<operations-field
										:label="$t('shipsituation.planned.plan.stopPlanned')"
										:value="convertDatesFromStopAIS(stop.entrydate)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('shipsituation.planned.plan.departurePlanned')"
										:value="convertDatesFromStopAIS(stop.destinationdate)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.berthname')" :value="stop.berthname"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.berthtype')" :value="stop.berthingtypename"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.initialBollard')" :value="stop.inibollard"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.endBollard')" :value="stop.endbollard"></operations-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</pui-field-set>
				</v-card-text>
				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="$emit('closePlanning')"> {{ $t('shipsituation.planned.plan.cancel') }} </v-btn>
					<v-btn color="primary" text @click="onOkPlanStops"> {{ $t('shipsituation.planned.plan.plan') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import OperationsField from '@/components/operations/map/commonpanel/stop/OperationsField';
/** Constants */
import { PLANNING_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'planningdialog',
	components: { OperationsField },
	mixins: [],
	props: {
		dialog: {
			type: Boolean,
			required: true
		},
		stopsToPlanning: {
			type: Array,
			default: []
		}
	},

	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat,
			stops: []
		};
	},

	created() {},

	computed: {
		dynamicPanels() {
			return this.$store.getters.dynamicPanelsPeriods;
		}
	},

	methods: {
		onOkPlanStops() {
			this.plan();
		},
		plan() {
			if (this.stops.length > 0) {
				let url = '/stop/planStops';
				let toPlan = [];
				this.stops.forEach((stop) => {
					toPlan.push({
						portAuthorityId: stop.portauthority,
						portCode: stop.portcode,
						portCallNumber: stop.portcallnumber,
						stopNumber: stop.num,
						berthCode: stop.berthcode,
						bollardIni: stop.inibollard,
						bollardEnd: stop.endbollard,
						etaPlan: stop.entrydate,
						etdPlan: stop.destinationdate,
						berthingType: stop.berthingtype
					});
				});

				this.$puiRequests.postRequest(
					url,
					toPlan,
					(response) => {
						console.log(response);
						this.$puiNotify.success(this.$t('shipsituation.planned.plan.success'));
						// Clean arrays
						this.$store.commit('setStopsFeaturesInstantPL', []);
						this.$store.commit('setStopsFeaturesInstantModifiedPL', []);
						this.$store.commit('setStopsFeaturesInstantWithModifiedPL', []);
						this.$store.commit('setStopsFeaturesInstantRemovedPL', []);

						// Cerramos las pestañas abiertas
						const dynamicTabsToMantain = this.dynamicPanels.filter((obj) => {
							return obj.instantPanel != PLANNING_PANEL_ID;
						});
						this.$store.commit('setDynamicPanelPeriods', dynamicTabsToMantain);

						// Refrescar mapa
						this.$puiEvents.$emit('map:updated');
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message);

						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			} else {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.notSelectedPlanning'));
			}
			this.$emit('closePlanning');
		},
		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		},
		dropStop(index) {
			this.stops.splice(index, 1);
		}
	},

	watch: {
		dialog() {
			this.stops = [];
			this.stopsToPlanning.forEach((st) => {
				if (st?.selected) {
					this.stops.push(st);
				}
			});
		}
	}
};
</script>

<style lang="postcss" scoped>
.stopsPlanning {
	border: 1px solid var(--primary);
}
.noselected-title {
	padding: 10px;
	font-size: 1.3rem;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: var(--high);
	margin-bottom: 2px;
	text-align: center; /* Add this line to center the text horizontally */
}
</style>
