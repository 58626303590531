<template>
	<div class="d-flex flex-column planningpanel fill-height max-height-100">
		<time-change-accept-info :dialogProp="dialog" @closeDialog="updateDialog" />
		<planning-dialog :dialog="dialogPlanning" :stopsToPlanning="allStopsComputed" @closePlanning="dialogPlanning = false" />

		<div class="d-flex flex-column px-3 mb-0 pt-3">
			<v-row v-if="idStopSelected != ''">
				<v-col cols="1" class="ml-6">
					<v-btn @click="backPanel" depressed icon height="32" width="32" class="closebtn ml-auto">
						<v-icon>fas fa-chevron-circle-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="9" class="ml-6">
					<div class="text-h6 font-weight-black">{{ $t('shipsituation.planned.title') }}</div>
				</v-col>
			</v-row>
			<v-row v-else>
				<div class="d-flex flex-column py-6 px-3 mb-3" ref="header">
					<div class="planningpanel-title">{{ $t('shipsituation.planned.title') }}</div>
				</div>
			</v-row>
		</div>

		<div class="pb-0 px-3">
			<v-row>
				<v-col v-if="idStopSelected == ''">
					<pui-date-field
						:id="`stopPlanned-fecIni`"
						v-model="$store.state.mappanelperiods.fecIniPlanningpanel"
						:label="$t('shipsituation.resquesteds.authorization.stopPlanned')"
						toplabel
						required
						time
						:max="$store.state.mappanelperiods.fecEndPlanningpanel"
						@focusin.native="timeFocused"
						:disabled="processVesselsRunning"
					></pui-date-field>
					<pui-date-field
						:id="`stopPlanned-fecEnd`"
						v-model="$store.state.mappanelperiods.fecEndPlanningpanel"
						:label="$t('shipsituation.resquesteds.authorization.departurePlanned')"
						toplabel
						required
						time
						:min="$store.state.mappanelperiods.fecIniPlanningpanel"
						@focusin.native="timeFocused"
						:disabled="processVesselsRunning"
					></pui-date-field>
				</v-col>
				<v-col v-else>
					<pui-date-field
						:id="`stopPlanned-fecIni`"
						v-model="feciniselected"
						:label="$t('shipsituation.resquesteds.authorization.stopPlanned')"
						toplabel
						required
						disabled
						time
					></pui-date-field>
					<pui-date-field
						:id="`stopPlanned-fecEnd`"
						v-model="fecendselected"
						:label="$t('shipsituation.resquesteds.authorization.departurePlanned')"
						toplabel
						required
						disabled
						time
					></pui-date-field>
				</v-col>
				<v-col v-if="idStopSelected == ''" cols="12" class="pt-0">
					<v-text-field
						ref="searchingtextplanningpanel"
						:placeholder="$t('operationstoolbar.search')"
						v-model="$store.state.mappanelperiods.searchingtextplanningpanel"
						@input="filterVesselsMapByText"
						prepend-inner-icon="far fa-search"
						outlined
						filled
						dense
						background-color="red"
						hide-details
					></v-text-field>
				</v-col>
			</v-row>

			<div
				v-if="!allPanels"
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = true"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-down</v-icon>
				{{ $t('operations.vessels.all') }}
			</div>
			<div
				v-else
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = false"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-up</v-icon>
				{{ $t('operations.vessels.none') }}
			</div>
		</div>

		<div class="lists pb-0 px-3">
			<pui-form-loading v-if="processVesselsRunning"></pui-form-loading>
			<cards-panel-items :stops="allStopsComputed" :all="allPanels" :currentmap="currentmap"></cards-panel-items>
		</div>

		<v-divider></v-divider>

		<div class="d-flex justify-space-around mt-1 mb-1 ml-3 mr-3">
			<v-btn style="width: 100%" color="primary" @click="planningAll()">{{ $t('shipsituation.planned.planningSel') }}</v-btn>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import CardsPanelItems from '../../infoais/vessel/cards/CardsPanelItems.vue';
import PlanningDialog from './PlanningDialog';
import TimeChangeAcceptInfo from '../TimeChangeAcceptInfo';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';

export default {
	name: 'planningmappanel',

	components: { CardsPanelItems, TimeChangeAcceptInfo, PlanningDialog },
	mixins: [StopsServiceMixin],
	props: {
		currentmap: {
			type: String,
			required: true
		},
		idStopSelected: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			allPanels: false,
			searchingtextplanningpanel: '',
			acceptClicked: false,
			dialog: false,
			dialogPlanning: false,
			feciniselected: null,
			fecendselected: null
		};
	},

	created() {
		this.isMapPeriodsIsLoaded &&
			ol2map.setVisibleFeatureByVesselText(
				this.$store.state.mappanelperiods.searchingtextplanningpanel,
				this.planningStatusCode,
				this.planningViewStatusCode
			);

		this.setDatesStopSelected();
	},

	computed: {
		isModifiying() {
			if (this.$store.getters.getStopsFeaturesInstantWithModifiedPL.length > 0) {
				return true;
			}
			return false;
		},
		planningStatusCode() {
			return this.$store.getters.getPlanningStatusCode;
		},
		planningViewStatusCode() {
			return this.$store.getters.getPlanningViewStatusMapCode;
		},
		isMapPeriodsIsLoaded() {
			return this.$store.getters.getMapPeriodsIsLoaded;
		},
		allStopsComputed() {
			var allStops = this.$store.getters.getStopsFeaturesInstantPL;
			var removedStops = this.$store.getters.getFeaturesInstantRemovedPL;
			if (this.isModifiying) {
				allStops = this.$store.getters.getStopsFeaturesInstantWithModifiedPL;
			}
			if (removedStops.length > 0) {
				const ids = removedStops.filter((stop) => 'id' in stop).map((stop) => stop['id']);
				allStops = allStops.filter((stop) => {
					return !ids.includes(stop.id);
				});
				allStops = [...allStops, ...removedStops];
			}
			return allStops
				.filter((x) => {
					const searchTextLower = this.$store.state.mappanelperiods.searchingtextplanningpanel.toLowerCase();

					const isVesselNameMatch = (x.vesselname ?? '').toLowerCase().includes(searchTextLower);
					const isMMSIMatch = (x.mmsi ?? '').toString().toLowerCase().includes(searchTextLower);
					const isTypeMatch = (x.vesseltypedescription ?? '').toLowerCase().includes(searchTextLower);

					return x.clickable && (isVesselNameMatch || isMMSIMatch || isTypeMatch || searchTextLower === '');
				})
				.sort((a, b) => {
					return (a.vesselname ?? '').localeCompare(b.vesselname);
				});
		},
		processVesselsRunning() {
			return this.$store.getters.getProcessVesselsRunning;
		}
	},

	methods: {
		setDatesStopSelected() {
			if (this.idStopSelected != '') {
				const jsonString = JSON.parse(atob(this.idStopSelected));
				this.feciniselected = jsonString.fecini;
				this.fecendselected = jsonString.fecend;
			} else {
				this.feciniselected = null;
				this.fecendselected = null;
			}
		},
		backPanel() {
			this.$router.push('/stop');
		},
		updateDialog() {
			this.dialog = false;
		},
		timeFocused() {
			if (this.isModifiying && !this.acceptClicked) {
				this.dialog = true;
			}
			this.acceptClicked = false;
		},
		updateDialog() {
			this.dialog = false;
			this.acceptClicked = true;
		},
		filterVesselsMapByText() {
			ol2map.setVisibleFeatureByVesselText(
				this.$store.state.mappanelperiods.searchingtextplanningpanel,
				this.planningStatusCode,
				this.planningViewStatusCode
			);
		},
		planningAll() {
			// Comprobamos si hay stops para autorizar
			if (this.$store.getters.getStopsFeaturesInstantModifiedPL.length > 0) {
				this.dialogPlanning = true;
			} else {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.notSelectedPlanning'));
			}
		}
	},

	watch: {
		'$store.state.mappanelperiods.mapPeriodsIsLoaded'() {
			this.changeInstantLayerOpacityFeatureByStatusid(this.planningStatusCode, this.planningViewStatusCode);
		}
	}
};
</script>

<style lang="postcss" scoped>
.planningpanel {
	background-color: #f7f8fc;
	& .list-icon {
		color: var(--primary);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: var(--primary);
		}
	}

	& .planningpanel-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
		margin-bottom: 2px;
	}

	& .planningpanel-subtitle {
		font-size: 14px;
		line-height: 16.5px;
		letter-spacing: normal;
		color: #6f7480;
		margin-top: 2px !important;
	}
}
.btn {
	border-radius: 2px !important;
	min-width: 25px !important;
	min-height: 100%;
	color: gray;
	&.btnleft {
		margin-right: 4px !important;
	}

	& i {
		color: var(--brownish-grey);
	}
	&:hover {
		background-color: var(--primary) !important;
		& i {
			color: var(--negative);
		}
	}
	&.selected {
		background-color: var(--accent !important);
		& i {
			color: var(--negative);
		}
	}
	&.applied {
		background-color: var(--warning);
		& i {
			color: black;
		}
	}
}
</style>
